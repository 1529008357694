/* Your existing CSS styles for the image slider... */

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.enlarged-image {
  width: 100%;
  height: auto;
}

.navigation-buttons {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}

.left-button,
.right-button {
  background-color: #ffffff;
  opacity: 0.8;
  padding: 0px 8px;
  color: #1c7196;
  border: none;
  font-size: 36px;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.left-button {
  left: 10px;
}

.right-button {
  right: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}
