@tailwind base;
@tailwind components;
@tailwind utilities;

.active {
  background-color: red;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background-color: rgb(213, 204, 204);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background: rgb(32, 87, 32);
  border-radius: 14px;
  border: 3px solid var(--primary);
}

